.container {
  align-self: flex-start;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  outline: none;
  margin: 0;
  font-weight: 600;
  padding: 9px var(--spacing-base);
  font-size: var(--text-base);
  // width: max-content;
  width: 100%;
  line-height: 1.5em;
  & svg {
    margin-right: 0.5rem;
  }
}
.border {
  background-color: var(--lightGrey);
  border: solid 2px;
}
.fill {
  color: var(--white) !important;
  border: solid 2px;

  &.hoverBlue:hover {
    background-color: var(--primary-light) !important;
    border-color: var(--primary-light) !important;
  }
  &.hoverRed:hover {
    background-color: var(--lightRed) !important;
    border-color: var(--lightRed) !important;
  }
}

.container:hover {
  text-decoration: none !important;
  color: var(--white);
  transform: scale(1.1);
  transition: all 200ms;
}

.isDisabled {
  background-color: var(--mediumGrey) !important;
  cursor: default !important;
  color: var(--lightGrey) !important;
  border-color: var(--mediumGrey) !important;
  font-weight: normal;
  &:hover {
    text-decoration: none !important;
    filter: none;
  }
}
.transparent {
  background-color: transparent !important;
  color: var(--white) !important;
  border: none;
  border-radius: 0 !important;
  border-bottom: 1px solid var(--white);
}
