.container {
  min-height: calc(100vh);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;

  label {
    text-align: left;
    align-self: flex-start;
    font-weight: 700;
  }
  & > div {
    flex: 1;
    display: flex;
    height: calc(100vh);
    width: 100%;
  }
}

.form {
  margin-top: var(--spacing-xl);
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 0 var(--spacing-xl);

  & .row {
    display: flex;
    flex-direction: row;
  }
}

.formContainer {
  // background-color: var(--lightGrey);
  flex-direction: column;
  padding: var(--spacing-4xl);
  overflow-y: scroll;
}

.formInnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 400px;
  margin: auto;
}

.imageContainer {
  background-image: url('/assets/bg_login.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.passwordFont input {
  font-family: initial;
}
.submitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .helpPassword {
    cursor: pointer;
    margin-top: 0;
    margin-left: auto;
    margin-bottom: var(--spacing-4xl);
    color: var(--primary-light);
  }
  .helpPassword:hover {
    border-bottom: solid 1px var(--secondary);
  }
}

.logo {
  width: 200px;
  justify-self: center;
  margin: var(--spacing-1xl) 8px;
}

.arrowBack {
  cursor: pointer;
}
.signUp {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: var(--spacing-2xl);
}
.signUpCTA {
  margin: 0;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    border-bottom: solid 1px var(--secondary);
  }
}
.mainError {
  color: red;
  width: 100%;
  margin: 0;
  padding: 0 8px;
  margin-bottom: var(--spacing-xl);
}
